import React from 'react';

export default function SimplePageHeading({ title, subtitle }) {
  return (
    <div className="py-24 lg:py-32">
      <div className="relative z-10 max-w-7xl mx-auto pl-4 pr-8 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl lg:text-6xl">
          {title}
        </h1>
        <p className="mt-6 text-xl text-gray-500 max-w-3xl">{subtitle}</p>
      </div>
    </div>
  );
}
