import React from 'react';
import DefaultLayout from '../layouts/DefaultLayout';
import SimplePageHeading from '../components/SimplePageHeading';

export default function TermsPage() {
  return (
    <DefaultLayout title="Terms &amp; Conditions">
      <SimplePageHeading
        title="Terms &amp; Conditions"
        subtitle="While you're here there's a few conditions of use to note"
      />

      <div className="max-w-7xl mx-auto pl-4 pr-8 sm:px-6 lg:px-8">
        <div className="max-w-prose prose prose-emerald prose-lg text-gray-500">
          <span className="text-xs uppercase font-medium">Updated 27th January 2022</span>
          <ol>
            <li>
              <strong>About the Website</strong>

              <ol type="a">
                <li>
                  Welcome to consentrally.com (the 'Website'). The Website allows you to access and
                  use Consentrally (the 'Services').
                </li>
                <li>
                  The Website is operated by Consentrally (Stack Advisory Pty Ltd), ACN 649 412 046
                  . Access to and use of the Website, or any of its associated Products or Services,
                  is provided by Consentrally. Please read these terms and conditions (the 'Terms')
                  carefully. By using, browsing and/or reading the Website, this signifies that you
                  have read, understood and agree to be bound by the Terms. If you do not agree with
                  the Terms, you must cease usage of the Website, or any of Services, immediately.
                </li>
                <li>
                  Consentrally reserves the right to review and change any of the Terms by updating
                  this page at its sole discretion. When Consentrally updates the Terms, it will use
                  reasonable endeavours to provide you with notice of updates to the Terms. Any
                  changes to the Terms take immediate effect from the date of their publication.
                  Before you continue, we recommend you keep a copy of the Terms for your records.
                </li>
              </ol>
            </li>

            <li>
              <strong>Acceptance of the Terms</strong>

              <p>
                You accept the Terms by remaining on the Website. Where the option is available in
                the user interface, you may also accept the Terms by clicking to accept or agree to
                the Terms.
              </p>
            </li>

            <li>
              <strong>About the Service</strong>

              <ol type="a">
                <li>
                  Consentrally is a platform that centralises the collection, storage and
                  facilitates lookup of your customer digital consent data.
                </li>
                <li>
                  Trial and paid accounts are currently offered. You acknowledge and agree that the
                  accounts offered, as well as the account features may change from time to time,
                  and may be governed by separate terms which apply specific to the account. Where
                  special account-specific terms apply, you will be informed, and must accept those
                  terms before you are given such an account. For the avoidance of doubt these Terms
                  apply unless otherwise agreed or amended by account-specific terms.
                </li>
                <li>
                  Some accounts may be governed by a separate Software Licensing Agreement with
                  Consentrally, which may amend the terms of use. For the avoidance of doubt these
                  Terms apply unless otherwise agreed or amended by the terms of an applicable
                  Software Licensing Agreement.
                </li>
              </ol>
            </li>

            <li>
              <strong>Acceptable use of the Service</strong>
              <ol type="a">
                <li>
                  Consentrally, its related features, and website must only be used lawfully.
                  Consentrally reserves the right to suspend, cancel, or otherwise deny access to
                  users and accounts who use the service:
                  <ol type="i">
                    <li>
                      To engage in any act that would disrupt the access, availability, and security
                      of Consentrally and other Consentrally services, including but not limited to:
                      <ol type="A">
                        <li>Tampering with, reverse-engineering, or hacking our servers.</li>
                        <li>
                          Modifying, disabling, or compromising the performance Consentrally or
                          other Consentrally services.
                        </li>
                        <li>
                          Overwhelming, or attempting to overwhelm our infrastructure by imposing an
                          unreasonably large load on our systems that consume extraordinary
                          resources.
                        </li>
                        <li>
                          Compromising the integrity of our system, including probing, scanning and
                          testing the vulnerability of our system unless expressly permitted by
                          Consentrally.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li>
                  For any illegal purpose, or to violate any laws, including and without limitation
                  to data, privacy, and export control laws.
                </li>
                <li>To stalk, harass or threaten users and any member of the public.</li>
                <li>
                  To misrepresent or defraud any user or member of the public through phishing,
                  spoofing, manipulating headers or other identifiers, impersonating anyone else, or
                  falsely implying any sponsorship or association with Consentrally or any third
                  party
                </li>
                <li>
                  To access or search any part of the Service, or any other Service owned by
                  Consentrally other than our publicly supported interface, or otherwise allowed for
                  in an applicable Software Licensing Agreement.
                </li>
                <li>
                  To post, upload, share, or otherwise circulate content in violation of
                  Consentrally’s content policy
                </li>
              </ol>
            </li>

            <li>
              <strong>Security and Data Privacy</strong>

              <p>
                Consentrally takes your privacy seriously and information provided through your use
                of the Website and/or Services are subject to Consentrally’s Privacy Policy, which
                is available on the Website. The Privacy Policy also addresses Consentrally’s
                processes, policies, and obligations in respect of Consentrally security breaches.
              </p>
            </li>

            <li>
              <strong>Data Use</strong>

              <p>
                Consentrally collects, stores, and processes your data on Consentrally. The data is
                used to provide Services to you, as well as to facilitate Consentrally’s business
                operations. The Privacy Policy outlined how your data is collected, stored, and
                processed by Consentrally. The Privacy Policy also addresses Consentrally's
                processes, policies, and obligations in respect of data encryption and removal
                requests.
              </p>
            </li>

            <li>
              <strong>Subscription to use the Service</strong>

              <ol type="a">
                <li>
                  In order to access the Services, you must first purchase a subscription through
                  the Website (the 'Subscription') and pay the applicable fee for the selected
                  Subscription (the 'Subscription Fee'). After purchasing a Subscription, you will
                  be considered a member (‘Member’).
                </li>
                <li>
                  In purchasing the Subscription, you acknowledge and agree that it is your
                  responsibility to ensure that the Subscription you elect to purchase is suitable
                  for your use.
                </li>
                <li>
                  Before, during or after you have purchased the Subscription, you will then be
                  required to register for an account through the Website before you can access the
                  Services (the ' Account').
                </li>
                <li>
                  As part of the registration process, or as part of your continued use of the
                  Services, you may be required to provide personal information about yourself (such
                  as identification or contact details), including but not limited to Name, email
                  address, phone number, company and credit card details.
                </li>
                <li>
                  You warrant that any information you give to Consentrally in the course of
                  completing the registration process will always be accurate, correct and up to
                  date.
                </li>
                <li>
                  Once you have completed the registration process, you will be a registered user of
                  the Website and agree to be bound by the Terms ("User"). As a Member you will be
                  granted immediate access to the Services from the time you have completed the
                  registration process until the subscription period expires (the 'Subscription
                  Period').
                </li>
                <li>
                  You may not use the Services and may not accept the Terms if:
                  <ol type="i">
                    <li>
                      you are not of legal age to form a binding contract with Consentrally; or
                    </li>
                    <li>
                      you are a person barred from receiving the Services under the laws of
                      Australia or other countries including the country in which you are resident
                      or from which you use the Services
                    </li>
                  </ol>
                </li>
              </ol>
            </li>

            <li>
              <strong>Payments</strong>

              <ol type="a">
                <li>
                  Subject to the terms of any applicable Software License Agreement, the
                  Subscription Fee may be paid by all payment methods available on the Website, and
                  may change from time to time.
                </li>
                <li>
                  Payments made in the course of your use of Consentrally may be made using
                  third-party applications and services not owned, operated, or otherwise controlled
                  by Consentrally. You acknowledge and agree that Consentrally will not be liable
                  for any losses or damage arising from the operations of third-party payment
                  applications and services. You further acknowledge and warrant that you have read,
                  understood and agree to be bound by the terms and conditions of the applicable
                  third-party payment applications and services you choose to use as a payment
                  method for Consentrally services.
                </li>
                <li>
                  You acknowledge and agree that where a request for the payment of the Subscription
                  Fee is returned or denied, for whatever reason, by your financial institution or
                  is unpaid by you for any other reason, then you are liable for any costs,
                  including banking fees and charges, associated with the Subscription Fee.
                </li>
                <li>
                  You agree and acknowledge that Consentrally can vary the Subscription Fee at any
                  time and that the varied Subscription Fee will come into effect following the
                  conclusion of the existing Subscription.
                </li>
              </ol>
            </li>

            <li>
              <strong>Refund Policy</strong>
              <p>
                Consentrally will only provide you with a refund of the Subscription Fee in the
                event they are unable to continue to provide the Services or if the manager of
                Consentrally makes a decision, at its absolute discretion, that it is reasonable to
                do so under the circumstances . Where this occurs, the refund will be in the
                proportional amount of the Subscription Fee that remains unused by the Member (the
                'Refund').
              </p>
            </li>

            <li>
              <strong>Copyright and Intellectual Property</strong>

              <ol type="a">
                <li>
                  The Website, the Services and all of the related products of Consentrally are
                  subject to copyright. The material on the Website is protected by copyright under
                  the laws of Australia and through international treaties. Unless otherwise
                  indicated, all rights (including copyright) in the Services and compilation of the
                  Website (including but not limited to text, graphics, logos, button icons, video
                  images, audio clips, Website, code, scripts, design elements and interactive
                  features) or the Services are owned or controlled for these purposes, and are
                  reserved by Consentrally or its contributors.
                </li>
                <li>
                  All trademarks, service marks and trade names are owned, registered and/or
                  licensed by Consentrally, who grants to you a worldwide, non-exclusive,
                  royalty-free, revocable license whilst you are a User to:
                  <ol type="i">
                    <li>use the Website pursuant to the Terms;</li>
                    <li>
                      copy and store the Website and the material contained in the Website in your
                      device's cache memory; and
                    </li>
                    <li>
                      print pages from the Website for your own personal and non-commercial use.
                    </li>
                  </ol>
                </li>
                <li>
                  Consentrally does not grant you any other rights whatsoever in relation to the
                  Website or the Services. All other rights are expressly reserved by Consentrally.
                </li>
                <li>
                  Consentrally retains all rights, title and interest in and to the Website and all
                  related Services. Nothing you do on or in relation to the Website will transfer
                  any:
                  <ol type="i">
                    <li>
                      business name, trading name, domain name, trade mark, industrial design,
                      patent, registered design or copyright, or
                    </li>
                    <li>
                      a right to use or exploit a business name, trading name, domain name, trade
                      mark or industrial design, or
                    </li>
                    <li>
                      a thing, system or process that is the subject of a patent, registered design
                      or copyright (or an adaptation or modification of such a thing, system or
                      process), to you.
                    </li>
                  </ol>
                </li>
                <li>
                  You may not, without the prior written permission of Consentrally and the
                  permission of any other relevant rights owners: broadcast, republish, up-load to a
                  third party, transmit, post, distribute, show or play in public, adapt or change
                  in any way the Services or third party Services for any purpose, unless otherwise
                  provided by these Terms. This prohibition does not extend to materials on the
                  Website, which are freely available for re-use or are in the public domain.
                </li>
              </ol>
            </li>

            <li>
              <strong>General Disclaimer</strong>

              <ol type="a">
                <li>
                  Nothing in the Terms limits or excludes any guarantees, warranties,
                  representations or conditions implied or imposed by law, including the Australian
                  Consumer Law (or any liability under them) which by law may not be limited or
                  excluded.
                </li>
                <li>
                  Subject to this clause, and to the extent permitted by law:
                  <ol type="i">
                    <li>
                      all terms, guarantees, warranties, representations or conditions which are not
                      expressly stated in the Terms are excluded; and
                    </li>
                    <li>
                      Consentrally will not be liable for any special, indirect or consequential
                      loss or damage (unless such loss or damage is reasonably foreseeable resulting
                      from our failure to meet an applicable Consumer Guarantee), loss of profit or
                      opportunity, or damage to goodwill arising out of or in connection with the
                      Services or these Terms (including as a result of not being able to use the
                      Services or the late supply of the Services), whether at common law, under
                      contract, tort (including negligence), in equity, pursuant to statute or
                      otherwise.
                    </li>
                  </ol>
                </li>
                <li>
                  Use of the Website and the Services is at your own risk. Everything on the Website
                  and the Services is provided to you "as is" and "as available" without warranty or
                  condition of any kind. None of the affiliates, directors, officers, employees,
                  agents, contributors and licensors of Consentrally make any express or implied
                  representation or warranty about the Services or any products or Services
                  (including the products or Services of Consentrally) referred to on the Website,
                  includes (but is not restricted to) loss or damage you might suffer as a result of
                  any of the following:
                  <ol type="i">
                    <li>
                      failure of performance, error, omission, interruption, deletion, defect,
                      failure to correct defects, delay in operation or transmission, computer virus
                      or other harmful component, loss of data, communication line failure, unlawful
                      third party conduct, or theft, destruction, alteration or unauthorised access
                      to records;
                    </li>
                    <li>
                      the accuracy, suitability or currency of any information on the Website, the
                      Services, or any of its Services related products (including third party
                      material and advertisements on the Website);
                    </li>
                    <li>
                      costs incurred as a result of you using the Website, the Services or any of
                      the products of Consentrally; and
                    </li>
                    <li>
                      the Services or operation in respect to links which are provided for your
                      convenience.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>

            <li>
              <strong>Limitation of Liability</strong>

              <ol type="a">
                <li>
                  Consentrally's total liability arising out of or in connection with the Services
                  or these Terms, however arising, including under contract, tort (including
                  negligence), in equity, under statute or otherwise, will not exceed the resupply
                  of the Services to you.
                </li>
                <li>
                  You expressly understand and agree that Consentrally, its affiliates, employees,
                  agents, contributors and licensors shall not be liable to you for any direct,
                  indirect, incidental, special consequential or exemplary damages which may be
                  incurred by you, however caused and under any theory of liability. This shall
                  include, but is not limited to, any loss of profit (whether incurred directly or
                  indirectly), any loss of goodwill or business reputation and any other intangible
                  loss.
                </li>
              </ol>
            </li>

            <li>
              <strong>Termination of Contract</strong>

              <ol type="a">
                <li>
                  The Terms will continue to apply until terminated by either you or by Consentrally
                  as set out below.
                </li>
                <li>
                  If you want to terminate the Terms, you may do so by:
                  <ol type="i">
                    <li>
                      not renewing the Subscription prior to the end of the Subscription Period;
                    </li>
                    <li>
                      providing Consentrally with 14 days days’ notice of your intention to
                      terminate; and
                    </li>
                    <li>
                      closing your accounts for all of the services which you use, where
                      Consentrally has made this option available to you.
                    </li>
                  </ol>
                </li>
                <li>
                  Any notices pursuant to Clause 13.2 above should be sent, in writing, to
                  Consentrally via the 'Contact Us' link on our homepage.
                </li>
                <li>
                  Consentrally may at any time, terminate the Terms with you if:
                  <ol type="i">
                    <li>
                      you do not renew the Subscription at the end of the Subscription Period;
                    </li>
                    <li>
                      you have breached any provision of the Terms or intend to breach any
                      provision;
                    </li>
                    <li>Consentrally is required to do so by law;</li>
                    <li>
                      the provision of the Services to you by Consentrally is, in the opinion of
                      Consentrally, no longer commercially viable.
                    </li>
                  </ol>
                </li>
                <li>
                  Subject to local applicable laws, Consentrally reserves the right to discontinue
                  or cancel your Subscription or Account at any time and may suspend or deny, in its
                  sole discretion, your access to all or any portion of the Website or the Services
                  without notice if you breach any provision of the Terms or any applicable law or
                  if your conduct impacts Consentrally's name or reputation or violates the rights
                  of those of another party.
                </li>
              </ol>
            </li>

            <li>
              <strong>Indemnity</strong>

              <p>
                You agree to indemnify Consentrally, its affiliates, employees, agents,
                contributors, third party content providers and licensors from and against:
              </p>
              <ol type="a">
                <li>
                  all actions, suits, claims, demands, liabilities, costs, expenses, loss and damage
                  (including legal fees on a full indemnity basis) incurred, suffered or arising out
                  of or in connection with Your Content;
                </li>
                <li>
                  any direct or indirect consequences of you accessing, using or transacting on the
                  Website or attempts to do so; and/or
                </li>
                <li>any breach of the Terms.</li>
              </ol>
            </li>

            <li>
              <strong>Dispute Resolution</strong>

              <ol type="a">
                <li>
                  <em>Compulsory:</em>
                  <p>
                    If a dispute arises out of or relates to the Terms, either party may not
                    commence any Tribunal or Court proceedings in relation to the dispute, unless
                    the following clauses have been complied with (except where urgent interlocutory
                    relief is sought).
                  </p>
                </li>
                <li>
                  <em>Notice:</em>
                  <p>
                    A party to the Terms claiming a dispute ('Dispute') has arisen under the Terms,
                    must give written notice to the other party detailing the nature of the dispute,
                    the desired outcome and the action required to settle the Dispute.
                  </p>
                </li>
                <li>
                  <em>Resolution:</em>
                  <p>
                    On receipt of that notice ('Notice') by that other party, the parties to the
                    Terms ('Parties') must:
                  </p>
                  <ol type="i">
                    <li>
                      Within 14 days days of the Notice endeavour in good faith to resolve the
                      Dispute expeditiously by negotiation or such other means upon which they may
                      mutually agree;
                    </li>
                    <li>
                      If for any reason whatsoever, 30 days days after the date of the Notice, the
                      Dispute has not been resolved, the Parties must either agree upon selection of
                      a mediator or request that an appropriate mediator be appointed by the
                      President of the Australian Mediation Association or his or her nominee;
                    </li>
                    <li>
                      The Parties are equally liable for the fees and reasonable expenses of a
                      mediator and the cost of the venue of the mediation and without limiting the
                      foregoing undertake to pay any amounts requested by the mediator as a
                      pre-condition to the mediation commencing. The Parties must each pay their own
                      costs associated with the mediation;
                    </li>
                    <li>The mediation will be held in Sydney, Australia.</li>
                  </ol>
                </li>
                <li>
                  <em>Confidential:</em>
                  <p>
                    All communications concerning negotiations made by the Parties arising out of
                    and in connection with this dispute resolution clause are confidential and to
                    the extent possible, must be treated as "without prejudice" negotiations for the
                    purpose of applicable laws of evidence.
                  </p>
                </li>
                <li>
                  <em>Termination of Mediation:</em>
                  <p>
                    If 5 days have elapsed after the start of a mediation of the Dispute and the
                    Dispute has not been resolved, either party may ask the mediator to terminate
                    the mediation and the mediator must do so.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <strong>Venue and Jurisdiction</strong>

              <p>
                The Services offered by Consentrally is intended to be viewed by residents of
                Australia. In the event of any dispute arising out of or in relation to the Website,
                you agree that the exclusive venue for resolving any dispute shall be in the courts
                of New South Wales.
              </p>
            </li>

            <li>
              <strong>Governing Law</strong>

              <p>
                The Terms are governed by the laws of New South Wales. Any dispute, controversy,
                proceeding or claim of whatever nature arising out of or in any way relating to the
                Terms and the rights created hereby shall be governed, interpreted and construed by,
                under and pursuant to the laws of New South Wales, Australia without reference to
                conflict of law principles, notwithstanding mandatory rules. The validity of this
                governing law clause is not contested. The Terms shall be binding to the benefit of
                the parties hereto and their successors and assigns.
              </p>
            </li>

            <li>
              <strong>Independent Legal Advice</strong>

              <p>
                Both parties confirm and declare that the provisions of the Terms are fair and
                reasonable and both parties having taken the opportunity to obtain independent legal
                advice and declare the Terms are not against public policy on the grounds of
                inequality or bargaining power or general grounds of restraint of trade.
              </p>
            </li>

            <li>
              <strong>Severance</strong>

              <p>
                If any part of these Terms is found to be void or unenforceable by a Court of
                competent jurisdiction, that part shall be severed and the rest of the Terms shall
                remain in force.
              </p>
            </li>
          </ol>
        </div>
      </div>
    </DefaultLayout>
  );
}
